// body {
//     background: url('../img/bg-img.webp') no-repeat;
// }

.base-layout {
    display: flex;
    min-height: 100vh;
    background: url('../img/bg-img.webp') no-repeat;
    background-size: cover;
    background-position: center;

    .sidebar {
        position: sticky;
        top: 10px;
        width: 250px;
        height: calc(100vh - 20px);
        padding: 20px;
        margin: 10px;
        background-color: #fff;
        border-radius: 14px;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.075);
        // overflow: hidden;
        transition: 0.3s;

        .logo {
            display: block;
            width: 120px;
            margin: 0px auto 40px;
            transition: 0.3s;
        }

        ul {
            padding: 0px;
            margin: 0px;
            list-style: none;

            li {
                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    font-size: 18px;
                    padding: 10px 15px;
                    margin-bottom: 10px;
                    border-radius: 8px;
                    color: #252525;
                    transition: 0.3s;
                    z-index: 9;

                    span {
                        width: max-content;
                        transition: 0.3s;
                        overflow: hidden;
                    }

                    i {
                        font-size: 22px;
                        margin-right: 20px;
                    }

                    &:hover {
                        color: var(--primary);
                    }

                    &.active {
                        color: var(--primary);
                        background-color: #fff;
                        box-shadow: -2px 5px 10px rgba(0, 0, 0, 0.065);
                        transform: translate(15px, -8px);
                        margin-bottom: 0px;
                    }
                }
            }
        }

        &.sidebar-collapse {
            width: 80px;

            .logo {
                width: 45px;
                margin-bottom: 90px;
            }

            ul {
                li {
                    a {
                        width: 45px;
                        padding: 10px;

                        // justify-content: center;
                        span {
                            width: 0px;
                        }

                        i {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }

        .toggle-btn {
            position: absolute;
            top: 80px;
            left: calc(100% - 20px);
            background-color: var(--primary);
            font-size: 20px;
            color: #fff;
            width: 40px;
            height: 40px;
            border: none;
            border-radius: 50%;
            transition: 0.2s;

            &.rotate {
                rotate: 180deg;
            }
        }
    }

    .main {
        width: 100%;

        .page-content {
            width: 100%;
            min-height: 85vh;
            padding: 10px 20px;
            // background: url('../img/bg-img.webp') no-repeat;
        }
    }
}

.navbar {
    position: sticky;
    top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 20px);
    padding: 15px 20px;
    margin: 10px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.075);
    z-index: 99;

    h2 {
        font-family: 'Outfit-SemiBold';
        font-size: 22px;
        color: var(--secondary);
        margin-bottom: 0px;

        span {
            color: var(--primary);
        }
    }

    .logo {
        width: 100px;
    }

    .nav-items {
        display: flex;
        align-items: center;

        .nav-link {
            position: relative;
            font-size: 18px;
            font-weight: 500;
            color: var(--secondary);
            margin-right: 20px;
            text-decoration: none !important;
            transition: 0.3s;

            i {
                padding-right: 5px;
                transition: 0.2s;
            }

            &:hover,
            &.active {
                color: var(--primary);
            }
        }

        .nav-btn {
            position: relative;
            font-size: 18px;
            color: #fff;
            padding: 10px 20px;
            border: none;
            border-radius: 6px;
            background-color: var(--primary);
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.20);
            transition: 0.3s;

            i {
                padding-right: 5px;
            }

            &:hover {
                transform: translateY(-5px);
                box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.20);
            }
        }
    }

    .profile {
        display: flex;

        img {
            width: 45px;
            height: 45px;
            outline: 3px solid var(--primary);
            border-radius: 50%;
        }

        h6 {
            font-family: 'Outfit-SemiBold';
            font-size: 18px;
            margin-bottom: 0px;
        }

        p {
            font-family: 'Outfit-SemiBold';
            font-size: 14px;
            color: #0006;
            margin-bottom: 0px;
        }
    }

    .nav-btn-secondary {
        background-color: transparent;
        padding: 10px;
        border: none;
        font-size: 22px;
        color: #000;

        &:hover {
            color: #df1010;
        }
    }
}

footer {
    margin: 5px;

    p {
        font-size: 14px;
        color: #626262;
        text-align: center;
        margin-bottom: 0px;
        position: absolute;
        bottom: 5%;
    }
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h1 {
        font-family: 'Outfit-SemiBold';
        font-size: 26px;
        color: #fff;
        margin-bottom: 0px;

        span {
            font-size: 22px;
            color: #ffffffd2;
        }
    }
}

.primary-btn,
.secondary-btn {
    display: flex;
    align-items: center;
    font-family: 'Outfit-SemiBold';
    font-size: 16px;
    color: #fff;
    background-color: var(--primary);
    padding: 10px 20px;
    border: none;
    border-radius: 8px;

    i {
        margin-right: 5px;
    }
}

.secondary-btn {
    background-color: var(--secondary);
}

.btn-loading {
    opacity: 0.8;
    pointer-events: none;

    i {
        transition: 0.3s;
        -webkit-animation: spinAni 1s linear infinite;
        -moz-animation: spinAni 1s linear infinite;
        animation: spinAni 1s linear infinite;
    }
}

.btn-green {
    background-color: rgb(0, 129, 0);
}

.btn-red {
    background-color: #e10505;
}

.custom-search {
    display: flex;
    border-radius: 8px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.065);
    overflow: hidden;

    input {
        font-size: 16px;
        padding: 10px 15px;
        min-width: 300px;
        border: none;
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #fff;
        background-color: var(--primary);
        padding: 10px 20px;
        border: none;
    }
}

.custom-modal {
    label {
        color: #000 !important;
    }
}

.modal-auth-form {
    display: flex;

}

.input-box {
    width: 100%;
    font-family: 'Outfit-Regular';
    margin-bottom: 20px;

    label {
        position: relative;
        font-size: 16px;
        margin-bottom: 5px;
        color: #fff;

        .err-meg {
            position: absolute;
            display: block;
            width: max-content;
            top: 0px;
            left: calc(100% + 15px);
            color: #df1010;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    &.req {
        label {
            &::after {
                content: '*';
                position: absolute;
                top: 0px;
                right: -10px;
                color: #df1010;
                font-size: 18px;
            }
        }
    }

    .input-field {
        position: relative;
    }

    input {
        width: 100%;
        border: 1px solid transparent;
        border-radius: 6px;
        font-size: 16px;
        padding: 10px 20px;
        background-color: #fff;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.075);
    }

    .input-after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        font-size: 20px;
        color: #e7c60a;
        border: none;
        background-color: transparent;
        z-index: 9;

        &.color-primary {
            color: var(--primary);
        }
    }

    &.input-error {
        input {
            border: 1px solid #df1010;
        }
    }
}

.chart-box {
    width: 100%;
    height: 100%;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.075);
    background-color: #fff;

    h2 {
        font-family: 'Outfit-SemiBold';
        font-size: 22px;
        color: #454545;
        margin-bottom: 0px;
    }

    p {
        font-size: 16px;
        color: #989898;
    }
}

.h-500 {
    height: 500px;
}

.auth-layout {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background: url('../img/bg-img.webp') no-repeat;
    background-position: center;
    background-size: cover;

    .auth-card {
        background-color: rgba(255, 255, 255, 0.333);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        padding: 30px;
        margin: 40px 0px;
        min-width: 25%;
        border-radius: 40px;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.075);
        transition: 0.3s;

        .auth-form {
            .logo {
                display: block;
                width: 180px;
                margin: 0px auto 20px;
            }

            h2 {
                font-family: 'Outfit-SemiBold';
                font-size: 26px;
                color: #fff;
                text-align: center;
                margin-bottom: 0px;
            }

            p {
                font-size: 16px;
                text-align: center;
                color: #fff;
            }

            label {
                color: #fff;
            }

            .auth-footer {
                padding-top: 10px;
                margin-bottom: 0px;

                a {
                    color: var(--primary);
                }
            }
        }
    }
}

.gird-view {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
}

.job-cols {
    margin-bottom: 10px;

    label {
        display: block;
        font-size: 18px;
        font-weight: 600;

        span {
            font-size: 16px;
        }
    }

    p {
        color: #4b4b4b;
        font-size: 16px;
        margin-bottom: 0px;
    }

    .select-btn {
        display: flex;
        align-items: center;
        border: none;
        border-radius: 8px;
        padding: 10px 15px;
        margin-right: 15px;
        font-size: 16px;
        color: #000;
        background-color: #dfdfdf;
        transition: 0.2s;

        i {
            width: 0px;
            opacity: 0;
        }

        &.selected {
            color: #fff;
            background-color: var(--primary);

            i {
                width: auto;
                opacity: 1;
                margin-right: 5px;
            }
        }
    }
}

.custom-input {
    border: none;
    border-radius: 6px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.075);
    padding: 10px 15px;
}

.custom-dragger {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 300px;
    height: 200px;
    background-color: #fff !important;
    // padding: 20px;
    border: 3px dashed #61616757;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    transition: 0.3s;

    &:hover {
        border-color: #1175c5;

        .custom-btn:first-child {
            border-color: #1175c5;
        }
    }

    i {
        font-size: 2.5rem;
        color: var(--primary);
    }

    h6 {
        font-family: 'Outfit-SemiBold';
        font-size: 20px;
        margin-top: 15px;
        margin-bottom: 13px;
    }

    p {
        font-family: 'Outfit-Regular';
        font-size: 14px;
        margin-bottom: 0px;
        color: var(--secondary);
    }

    .custom-btn {
        padding: 25px 0px;
        width: 100%;
        transition: 0.2s;

        &:hover {
            background-color: #2495f13b;
        }

        &:first-child {
            border-bottom: 3px dashed #61616757;
        }
    }

    .upload-area {
        padding: 50px;
    }

    .ant-upload-list {
        width: 100%;
        height: 100%;
    }

    .ant-upload-select {
        width: 100% !important;
        height: 100% !important;
        margin: 0px !important;
        background-color: #fff !important;
        border: none !important;
        border-radius: 20px !important;
        transition: 0.3s;
    }

    .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item-container {
        width: 150px !important;
        height: 150px !important;
        margin: 0px !important;
        transition: 0.3s;
    }

    .hide-upload {
        .ant-upload-select {
            display: none;
        }
    }
}

.tab-wrapper {
    display: flex;
    border-bottom: 1px solid #EAECF0;

    button {
        color: #667085;
        font-size: 16px;
        font-weight: 600;
        padding: 11px 12px;
        margin-right: 10px;
        border: none;
        border-bottom: 2px solid transparent;
        background-color: transparent;

        span {
            border-radius: 20px;
            border: 1px solid #EAECF0;
            background: #F9FAFB;
            mix-blend-mode: multiply;
            padding: 3px 8px;
            margin-left: 5px;
        }

        &.active {
            color: var(--primary);
            border-color: var(--primary);

            span {
                background: #F9F5FF;
            }
        }
    }
}

.tab-panel {
    display: none;

    &.active {
        display: block;
    }
}

.h-m-0 {
    height: auto;
}

/* Lablet Responsive Design */
@media (max-width: 1060px) {}

/* Mobile Responsive Design */
@media (max-width: 767px) {
    .auth-layout {
        .auth-card {
            width: 90%;
            padding: 20px;

            .auth-form {
                .logo {
                    width: 150px;
                }
            }
        }
    }

    .base-layout {
        .sidebar {
            width: 65px;
            padding: 10px;

            .logo {
                width: 45px;
                margin-bottom: 50px;
            }

            .toggle-btn {
                display: none;
            }

            ul {
                li {
                    a {
                        width: 45px;
                        padding: 10px;

                        span {
                            width: 0px;
                        }

                        i {
                            margin-right: 0px;
                        }

                        &.active {
                            transform: translate(18px, -10px);
                        }
                    }
                }
            }
        }

        .main {
            width: calc(100% - 85px);

            .page-content {
                width: 100%;
                padding: 10px;
            }
        }
    }

    .navbar {
        h2 {
            display: none;
        }
    }

    .page-header h1 {
        font-family: "Outfit-SemiBold";
        font-size: 20px;
    }

    .chart-box {
        padding: 20px;

        h2 {
            font-size: 18px;
        }

        p {
            font-size: 12px;
        }
    }

    .primary-btn {
        font-size: 14px;
    }

    .custom-search {
        input {
            width: 90%;
            min-width: 100px;
        }
    }
}

/* Small Mobile Responsive Design */
@media (max-width: 576px) {}