@font-face {
    font-family: 'Outfit-Light';
    src: url(../font/Outfit-Light.ttf);
}

@font-face {
    font-family: 'Outfit-Medium';
    src: url(../font/Outfit-Medium.ttf);
}

@font-face {
    font-family: 'Outfit-Regular';
    src: url(../font/Outfit-Regular.ttf);
}

@font-face {
    font-family: 'Outfit-SemiBold';
    src: url(../font/Outfit-SemiBold.ttf);
}

@font-face {
    font-family: 'Outfit-Bold';
    src: url(../font/Outfit-Bold.ttf);
}

@font-face {
    font-family: 'Outfit-Black';
    src: url(../font/Outfit-Black.ttf);
}

:root{
    --primary: #2095f2;
    --secondary: #3d3d45;
    --background: #FAF9FF;
}

*{
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}

body{
    font-family: 'Outfit-Regular' !important;
    background-color: var(--background) !important;
}

input:focus{
    outline: none !important;
}

input::placeholder{
    color: #bfbfbf !important;
}

a {
    text-decoration: none !important;
}

@media (max-width: 767px) {
    .w-sm-100{
        width: 100% !important;
    }
}