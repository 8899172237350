.custom-table table {
    border-radius: 20px !important;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.065);
    overflow: hidden;

    .ant-table-thead {
        th {
            background-color: var(--primary);
            font-family: 'Outfit-SemiBold';
            font-size: 16px;
            color: #fff;
        }
    }

    .ant-table-tbody {
        td {
            font-family: 'Outfit-Regular';
            font-size: 14px;
            padding: 5px 16px;

            .ant-tag {
                font-family: 'Outfit-Regular';
                font-size: 14px;
            }

            .action-btn {
                border: none;
                background-color: transparent;
                color: var(--secondary);
                font-size: 20px;
                padding: 5px 10px;
                margin: 0px 5px;
                transition: 0.3s;

                &:hover {
                    color: var(--primary);
                }
            }
        }
    }

    input.ant-input {
        font-family: 'Outfit-Regular';
        font-size: 16px;
    }
}

.custom-modal {
    .ant-modal-content {
        padding: 0px;
        border-radius: 18px;
        overflow: hidden;
    }

    .ant-modal-header {
        padding: 15px 20px;
        margin-bottom: 0px;
        background-color: var(--primary);

        .ant-modal-title {
            font-family: 'Outfit-SemiBold';
            font-size: 22px;
            text-align: center;
            color: #fff;
        }
    }

    .ant-modal-body {
        padding: 20px 40px 5px;
    }

    .ant-modal-footer {
        text-align: center;
        padding-bottom: 20px;

        button {
            font-family: 'Outfit-SemiBold';
            font-size: 16px;
            color: #7c7c7c;
            margin-inline-start: 0px !important;
            margin: 0px 10px;
            padding: 6px 25px;
            border-radius: 8px;
            height: auto;

            &:disabled {
                opacity: 0.8;
                color: #fff;
                background-color: var(--primary);
            }

            &.ant-btn-primary {
                color: #fff;
            }
        }
    }

    .ant-modal-close {
        top: 20px;
        color: #fff;

        &:hover {
            background-color: transparent;
            color: #ce0e0e;
        }

        .ant-modal-close-x {
            font-size: 20px;
        }
    }
}

.ant-notification-notice-message {
    margin-bottom: 0px !important;
}

.custom-table .ant-table {
    background-color: transparent;
}

.custom-table .ant-table .ant-table-tbody {
    background-color: #fff;
}

.custom-datepicker {
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.075);

    .ant-picker-input {
        input {
            box-shadow: none;
            font-family: 'Outfit-Regular';
            font-size: 16px;
        }
    }
}

.ant-pagination.custom-pagination {
    .ant-pagination-item {
        font-family: 'Outfit-SemiBold';
        font-size: 16px;
        color: #7c7c7c;
        min-width: 30px;
        height: 30px;
        line-height: 27px;
    }

    .ant-pagination-item-link {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #7c7c7c;
    }

    .ant-pagination-item-active {
        color: #fff;
        background-color: var(--primary);
    }

    .ant-pagination-disabled .ant-pagination-item-link {
        color: var(--secondary);
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        min-width: 25px;
        height: 30px;
    }
}

.custom-select .ant-select-selector {
    width: 100%;
    border: none !important;
    border-radius: 6px !important;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.075);
    height: auto !important;
    padding: 7px 15px !important;
}

.custom-select .ant-select-selector .ant-select-selection-search-input {
    font-family: 'Outfit-Regular' !important;
    font-size: 16px !important;
    height: auto !important;
    padding: 7px 0px !important;
}

.custom-select .ant-select-selection-placeholder {
    font-family: 'Outfit-Regular' !important;
    font-size: 16px !important;
}

.custom-select {
    .ant-select-selection-placeholder {
        inset-inline-start: 15px !important;
    }

    .ant-select-selection-search {
        margin-inline-start: 0px !important;
    }
}

.input-error {

    .custom-datepicker,
    .ant-select-selector {
        border: 1px solid #df1010 !important;

        input {
            border: none !important;
        }
    }
}

@media (max-width: 767px) {
    .custom-table {
        width: 100%;

        .ant-table {
            overflow-x: scroll;
        }
    }

    .pt-m-0 {
        padding-top: 0px;
    }
}


.custom-table1 table .ant-table-tbody td {
    padding: 2px 16px !important
}

button.primary-btn.ml-auto {
    margin-left: auto
}